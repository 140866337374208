<div class="flex flex-col p-1.5">
  @for (action of actions; track action) {
    <button
      class="max-w-96 rounded-xl p-2 flex items-center gap-2 text-secondary min-w-40"
      [ngClass]="action.status === Status.ERROR ? 'hover:bg-status-negative-pale hover:text-status-negative' : 'hover:bg-neutral-1 hover:text-primary'"
      (click)="action.execute(entity)"
    >
      <tui-icon  data-testid="icon" [icon]="action.icon" class="h-5"></tui-icon>
      <p class="text-current line-clamp-1 text-left mr-2">
        {{ action.label }}
      </p>
    </button>
  }
</div>
