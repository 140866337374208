import { TuiBadge, TuiStatus } from '@taiga-ui/kit';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';

@Component({
  selector: 'pxc-user-admin-state-badge',
  standalone: true,
  imports: [
    CommonModule,
    TuiBadge,
    TuiStatus,
  ],
  templateUrl: './user-admin-state-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAdminStateBadgeComponent {

  @Input() state: UserAdminState;
  @Input() count?: number;

  UserState = UserAdminState;

}
