import { TUI_CONFIRM } from '@taiga-ui/kit';
import { Inject, Injectable, Injector, Type } from '@angular/core';
import { TuiDialogOptions, TuiDialogService, TuiDialogSize } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@taiga-ui/polymorpheus';
import { BehaviorSubject, Observable, Subject, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  public readonly exitConfirmDialog = this.openConfirm('Êtes vous sûr de vouloir annuler cette action ?', {
    content: 'Les informations saisies ne seront pas sauvegardées.',
    yes: 'Oui',
    no: 'Non',
  });

  constructor(
    @Inject(TuiDialogService) private readonly tuiDialogService: TuiDialogService,
    @Inject(Injector) private readonly injector: Injector,
  ) { }

  openConfirm(label: string, data?: {
    content?: string;
    yes?: string;
    no?: string;
    size?: string;
  }): Observable<boolean> {
    return this.tuiDialogService.open<boolean>(TUI_CONFIRM, {
      label,
      data,
      size: (data.size ?? 's') as TuiDialogSize,
      closeable: false,
      dismissible: false,
    });
  }

  openComponent<T, O>(
    component: Type<T>,
    options: Partial<TuiDialogOptions<Partial<T>>>,
  ): Observable<O> {
    return this.tuiDialogService.open<O>(
      new PolymorpheusComponent(component, this.injector),
      options,
    );
  }

  openComponentWithCloseConfirmation<T, O>(
    component: Type<T>,
    options: Partial<TuiDialogOptions<Partial<T>>>,
  ): Observable<O> {
    const { shouldAskConfirmation$, closeable$ } = this.getShouldAskConfirmation();
    return this.openComponent<T, O>(component, {
      ...options,
      closeable: closeable$,
      dismissible: closeable$,
      data: {
        ...options.data,
        shouldAskConfirmation$,
      },
    });
  }

  private getShouldAskConfirmation(): {
    shouldAskConfirmation$: Subject<boolean>;
    closeable$: Observable<boolean>;
  } {
    const requireConfirmation = new BehaviorSubject<boolean>(false);
    return {
      shouldAskConfirmation$: requireConfirmation,
      closeable$: requireConfirmation.pipe(
        switchMap((value) => {
          if (value) {
            return this.exitConfirmDialog;
          }
          return of(true);
        }),
      ),
    };
  }

}
