<button class="flex gap-6 p-6 ring-1 ring-border-normal transition bg-base hover:shadow-lg hover:ring-0 focus:shadow-lg focus:ring-0 rounded-3xl text-left w-full h-full">
  <div>
    <tui-icon 
      [icon]="icon"
      data-testid="card-button-icon" class="mb-2"
    ></tui-icon>
    <p class="text-lg font-medium">{{title}}</p>
    <p class="text-secondary text-sm">{{description}}</p>
  </div>
  <tui-icon 
    icon="@tui.chevron-right"
    class="flex-shrink-0 text-tertiary ml-auto self-center"
  ></tui-icon>
</button>
