<button 
  tuiButton 
  [appearance]="appearance" 
  data-testid="button" 
  [iconStart]="icon" 
  [tuiDropdown]="dropdownContent"
  tuiDropdownAlign="right"
  [(tuiDropdownOpen)]="open"
>
  <div class="flex gap-3">
    {{ actions[selectedKey].title }}
    <tui-icon 
      icon="@tui.chevron-down"
      class="transition-all duration-300"
      [ngClass]="{ 'rotate-180': open }"
    ></tui-icon>
  </div>
</button>

<ng-template #dropdownContent>
  <div class="p-2 flex flex-col" data-testid="dropdown">
    @for (actionsKeyValue of (actions | keyvalue); track actionsKeyValue) {
      <div
        class="flex items-center p-2 rounded-xl hover:bg-neutral-1 transition-colors duration-300 cursor-pointer"
      >
        <div *tuiLet="actionsKeyValue.value as action" (click)="onClick(action)" class="flex gap-2 items-center justify-between w-full">
          <div class="flex items-center gap-3">
            @if (action.icon) {
              <tui-icon [icon]="action.icon" class="text-secondary"></tui-icon>
            }
            <div class="flex flex-col">
              <p class="whitespace-normal font-semibold">
                {{ action.title }}
              </p>
              @if (action.label) {
                <p class="whitespace-normal text-secondary">
                  {{ action.label }}
                </p>
              }
            </div>
          </div>
          @if (actionsKeyValue.key === selectedKey) {
            <tui-icon
              class="text-secondary"
              icon="@tui.check"
            ></tui-icon>
          }
        </div>
      </div>
    }
  </div>
</ng-template>
