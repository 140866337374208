<pxc-tile
  [size]="size"
  [vertical]="vertical"
  >
  <pxc-avatar
    leading
    [size]="size"
    [name]="(user?.firstName || user?.lastName) ? user.firstName + ' ' + user.lastName : '@'"
  ></pxc-avatar>
  @if (!user && mailAddress) {
    <ng-container title>{{ mailAddress }}</ng-container>
  }
  @if (user) {
    <ng-container title>
      <tui-line-clamp
        [content]="userIdentity"
        [linesLimit]="1"
        [lineHeight]="20"
        [ngClass]="{'max-w-24': vertical}"
      ></tui-line-clamp>
      <ng-template #userIdentity>
        <span>{{ user.firstName }} {{ user.lastName }}</span>
      </ng-template>
    </ng-container>
  }
  @if (showSpecialty && user) {
    <ng-container subtitle>
      <span>{{ user.medicalSpecialtyLabel }}</span>
      <span *tuiLet="(user.clientNames | filteredJoin:'Mon Espace Personnel') as clientNames">
        @if (user.medicalSpecialtyLabel && clientNames) {
          <span>, </span>
        }
        {{ clientNames }}
      </span>
      @if (user && mailAddress) {
        <span class="leading-tight">{{ mailAddress }}</span>
      }
    </ng-container>
  }
</pxc-tile>