<button
  tuiIconButton
  [iconStart]="direction === HandleDirection.VERTICAL 
      ? '@tui.chevron-up'
      : '@tui.chevron-right'"
  type="button"
  size="s"
  appearance="floating"
  class="transition-transform"
  [class.rotate-0]="handleState"
  [class.rotate-180]="!handleState"
  (click)="toggleHandleState()"
  data-testid="handle"
></button>