import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { Observable, combineLatest, map, of } from 'rxjs';
import { ParsedActionButton } from 'src/app/shared/models/menu-actions/parsed-action-button';

@Component({
  selector: 'pxc-action-buttons',
  templateUrl: './action-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionButtonsComponent<T> implements OnChanges {

  @Input() actions: MenuAction<T>[];
  @Input() entity: T;

  @Input() actionButtons?: ActionButton[];

  @Input() optionButtonLabel: string;
  @Input() optionButtonAppearance = 'flat';

  @Input() size: 'xs' | 's' | 'm' | 'l'  = 'm';

  isActionsDropdownOpen = false;

  moreActions$: Observable<MenuAction<T>[]>;
  parsedActionButtons$: Observable<ParsedActionButton<T>[]>;

  ngOnChanges() {

    const actionButtonsIds = (this.actionButtons || [])
      .map((actionButton) => actionButton.actionIds).flat();

    const validActions$ = combineLatest((this.actions ?? []).map((action) =>
      this.isMenuActionValid(action, this.entity),
    )).pipe(
      map((results) => this.actions.filter((_, index) => results[index])),
    );

    this.moreActions$ = validActions$.pipe(
      map((actions) => actions.filter((action) =>
        !actionButtonsIds.includes(action.id))),
    );

    if (this.actionButtons) {

      this.parsedActionButtons$ = validActions$.pipe(
        map((validActions) => this.actionButtons.map((actionButton) => ({
          ...actionButton,
          actions: (actionButton.actionIds && actionButton.actionIds.length)
            ? validActions.filter((action) => actionButton.actionIds.includes(action.id))
            : [],
          isOpen: false,
        }))
          .filter((actionButton) => actionButton.actions.length),
        ));

    }

  }

  isMenuActionValid(menuAction: MenuAction<T>, entity: T): Observable<boolean> {
    if (!menuAction.condition && !menuAction.condition$) {
      return of(true);
    }

    // We validate if there is no condition or if the condition is valid
    const syncConditionResult = !menuAction.condition || menuAction.condition(entity);

    if (!menuAction.condition$) {
      return of(syncConditionResult);
    }

    return menuAction.condition$(entity)
      .pipe(map((show) => show && syncConditionResult));
  }

}
