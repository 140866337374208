<div class="flex flex-col gap-4">
  @if (title) {
    <h3 tuiTitle="m" data-testid="title">
      {{ title }}
    </h3>
  }
  <div class="grid md:grid-cols-2 gap-3 mx-1">
    <ng-content></ng-content>
  </div>
</div>
