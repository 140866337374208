<div class="flex gap-2 max-sm:flex-1 items-center flex-wrap" data-testid="action-buttons">
  
  @if (parsedActionButtons$ | async; as parsedActionButtons) {
    @if (parsedActionButtons?.length > 0) {
        @for (actionButton of parsedActionButtons; track actionButton.title+actionButton.actionIds.join('')) {
          <ng-container *tuiLet="{ 
              hasDropdown: actionButton.actions.length > 1,
              title: actionButton.title ?? actionButton.actions[0].label
            } as data"
          >
            @if (data.title) {
              <button
                type="button"
                tuiButton
                [iconStart]="actionButton.icon"
                [iconEnd]="data.hasDropdown ? '@tui.chevron-down' : null"
                [appearance]="actionButton.appearance"
                [tuiHint]="actionButton.hint"
                [tuiHintAppearance]="actionButton.appearance === 'destructive' ? 'error': ''"
                [tuiAppearanceState]="data.hasDropdown && actionButton.isOpen ? 'active' : null"
                (click)="data.hasDropdown ? null : actionButton.actions[0].execute(entity)"
                [attr.data-testid]="'button-' + actionButton.title"
                [size]="size"
                tuiDropdownAlign="right"
                [tuiDropdown]="data.hasDropdown ? content : null"
                (tuiDropdownOpenChange)="actionButton.isOpen = $event"
                [tuiDropdownOpen]="actionButton.isOpen"
              >
                {{ data.title }}
              </button>
            } @else {
              <button
                type="button"
                tuiIconButton
                [iconStart]="actionButton.icon"
                [appearance]="actionButton.appearance"
                [tuiHint]="actionButton.hint"
                [tuiHintAppearance]="actionButton.appearance === 'destructive' ? 'error': ''"
                [tuiAppearanceState]="data.hasDropdown && actionButton.isOpen ? 'active' : null"
                (click)="data.hasDropdown ? null : actionButton.actions[0].execute(entity)"
                [attr.data-testid]="'button-' + actionButton.title"
                [size]="size"
                [tuiDropdown]="data.hasDropdown ? content : null"
                (tuiDropdownOpenChange)="actionButton.isOpen = $event"
                [tuiDropdownOpen]="actionButton.isOpen"
              ></button>
            }
            <ng-template #content>
              <pxc-menu-actions [entity]="entity" [actions]="actionButton.actions"></pxc-menu-actions>
            </ng-template>
          </ng-container>
        }
    }
  }

  @if (moreActions$ | async; as moreActions) {
    @if (moreActions?.length > 0) {
      <tui-hosted-dropdown
        data-testid="more-button"
        tuiDropdownAlign="right"
        tuiDropdownDirection="bottom"
        [tuiDropdown]="moreActionsMenu" 
        [(tuiDropdownOpen)]="isActionsDropdownOpen"
      >
        @if (!optionButtonLabel) {
          <button
            tuiIconButton
            type="button"
            [appearance]="optionButtonAppearance"
            [tuiAppearanceState]="isActionsDropdownOpen ? 'active' : null"
            iconStart="@tui.ellipsis-vertical"
            [size]="size"
          ></button>
        } @else {
          <a 
            tuiLink
            [tuiAppearanceState]="isActionsDropdownOpen ? 'active' : null"
            appearance="flat"
          >
            {{ optionButtonLabel }}
            <tui-icon icon="@tui.ellipsis-vertical" class="h-4"></tui-icon>
          </a>
        }
      </tui-hosted-dropdown>
    }
    <ng-template #moreActionsMenu>
      <pxc-menu-actions [entity]="entity" [actions]="moreActions"></pxc-menu-actions>
    </ng-template>
  }

</div>
