<div #zoomContainer
  class="h-full relative group"
  (wheel)="wheelEvent($event)"
  [ngClass]="{
    'overflow-hidden': !enableOverflow, 
    'cursor-grabbing': isUserMoving(), 
    'cursor-grab': isMovingAllowed()
  }"
>
  <div #slider class="h-full origin-top-left"
    [style.transform]="getTransformStyle()"
    [style.transition]="!this.isUserClicking ? 'transform .3s ease-out' : ''"
    (mousedown)="enableMoving()"
    (mousemove)="mouseMove($event)"
    (mouseup)="disableMoving()"
    (mouseout)="disableMoving()"
  >
    <ng-content></ng-content>
  </div>
  
@if (zoomButtons) {
  <div 
    class="
      hidden rounded-xl group-hover:flex
      absolute bottom-4 left-1/2 -translate-x-1/2
      transition-all duration-300 gap-2
    " 
  >
    <button tuiIconButton 
      iconStart="@tui.zoom-out"
      (click)="zoomOut()"
      appearance="glass"
      [disabled]="isContainerZoomMin()"
    ></button>
    <button tuiIconButton
      iconStart="@tui.zoom-in"
      (click)="zoomIn()"
      appearance="glass"
      [disabled]="isContainerZoomMax()"
    ></button>
  </div>
}
</div>
